import store from '@/state/store'

export default [
    {
        path: '/login',
        name: 'login',
        component: () => import('../views/pages/account/login'),
        meta: {
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                if (store.getters['auth/loggedIn']) {
                    // Redirect to the home page instead
                    next({ name: 'home' })
                } else {
                    // Continue to the login page
                    next()
                }
            },
        },
    },
    {
        path: '/register',
        name: 'register',
        component: () => import('../views/pages/account/register'),
        meta: {
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                if (store.getters['auth/loggedIn']) {
                    // Redirect to the home page instead
                    next({ name: 'home' })
                } else {
                    // Continue to the login page
                    next()
                }
            },
        },
    },
    {
        path: '/forgot-password',
        name: 'Forgot-password',
        component: () => import('../views/pages/account/forgot-password'),
        meta: {
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                if (store.getters['auth/loggedIn']) {
                    // Redirect to the home page instead
                    next({ name: 'home' })
                } else {
                    // Continue to the login page
                    next()
                }
            },
        },
    },
    {
        path: '/logout',
        name: 'logout',
        meta: {
            authRequired: true,
            beforeResolve(routeTo, routeFrom, next) {
                if (process.env.VUE_APP_DEFAULT_AUTH === "firebase") {
                    store.dispatch('auth/logOut')
                } else {
                    store.dispatch('authfack/logout')
                }
                const authRequiredOnPreviousRoute = routeFrom.matched.some(
                    (route) => route.push('/login')
                )
                // Navigate back to previous page, or home as a fallback
                next(authRequiredOnPreviousRoute ? { name: 'home' } : { ...routeFrom })
            },
        },
    },
    {
        path: '/',
        name: 'home',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/dashboard/index')
    },

    {
        path: '/change-password',
        name: 'Change Password',
        meta: { authRequired: true },
        component: () => import('../views/pages/master/change-password')
    },


    {
        path: '/cms/home',
        name: 'Home',
        meta: { authRequired: true },
        component: () => import('../views/pages/master/cms/home')
    },
    {
        path: '/cms/logo',
        name: 'Logo',
        meta: { authRequired: true },
        component: () => import('../views/pages/master/cms/image_home_logo')
    },
		{
				path: '/cms/logo-footer',
				name: 'Logo Footer',
				meta: { authRequired: true },
				component: () => import('../views/pages/master/cms/image_home_logo_footer')
		},
    {
        path: '/cms/home/image-top',
        name: 'Home',
        meta: { authRequired: true },
        component: () => import('../views/pages/master/cms/image_home_top')
    },
    {
        path: '/cms/home/image-level3',
        name: 'Home',
        meta: { authRequired: true },
        component: () => import('../views/pages/master/cms/image_home_level3')
    },
    {
        path: '/cms/home/image-level2',
        name: 'Home',
        meta: { authRequired: true },
        component: () => import('../views/pages/master/cms/image_home_level2')
    },
    {
        path: '/cms/about-us',
        name: 'About Us',
        meta: { authRequired: true },
        component: () => import('../views/pages/master/cms/about_us')
    },
		{
				path: '/cms/contact-us',
				name: 'Contact Us',
				meta: { authRequired: true },
				component: () => import('../views/pages/master/cms/contact_us')
		},
		{
				path: '/cms/footer',
				name: 'Footer',
				meta: { authRequired: true },
				component: () => import('../views/pages/master/cms/footer')
		},
    {
        path: '/cms/setting',
        name: 'Setting',
        meta: { authRequired: true },
        component: () => import('../views/pages/master/cms/setting')
    },

    {
        path: '/banner',
        name: 'Banner',
        meta: { authRequired: true },
        component: () => import('../views/pages/content/banner')
    },
    {
        path: '/banner/action/add',
        name: 'Banner Form Add',
        meta: { authRequired: true },
        component: () => import('../views/pages/content/banner/form')
    },
    {
        path: '/banner/action/edit/:id',
        name: 'Banner Form Edit',
        meta: { authRequired: true },
        component: () => import('../views/pages/content/banner/form')
    },
		
		{
				path: '/contact-us',
				name: 'Contact us',
				meta: { authRequired: true },
				component: () => import('../views/pages/content/contact_us')
		},
		{
				path: '/contact-us/action/add',
				name: 'Contact us Form Add',
				meta: { authRequired: true },
				component: () => import('../views/pages/content/contact_us/form')
		},
		{
				path: '/contact-us/action/edit/:id',
				name: 'Contact us Form Edit',
				meta: { authRequired: true },
				component: () => import('../views/pages/content/contact_us/form')
		},
		
		{
				path: '/property-type',
				name: 'Property Type',
				meta: { authRequired: true },
				component: () => import('../views/pages/content/property_type')
		},
		{
				path: '/property-type/action/add',
				name: 'Property Type Form Add',
				meta: { authRequired: true },
				component: () => import('../views/pages/content/property_type/form')
		},
		{
				path: '/property-type/action/edit/:id',
				name: 'Property Type Form Edit',
				meta: { authRequired: true },
				component: () => import('../views/pages/content/property_type/form')
		},
		
		{
				path: '/property',
				name: 'Property',
				meta: { authRequired: true },
				component: () => import('../views/pages/content/property')
		},
		{
				path: '/property/action/add',
				name: 'Property Form Add',
				meta: { authRequired: true },
				component: () => import('../views/pages/content/property/action')
		},
		{
				path: '/property/action/edit/:id',
				name: 'Property Form Edit',
				meta: { authRequired: true },
				component: () => import('../views/pages/content/property/action')
		},
		{
				path: '/property/detail/:id',
				name: 'Property Detail',
				meta: { authRequired: true },
				component: () => import('../views/pages/content/property/detail')
		},
		
		{
				path: '/customer',
				name: 'Customer',
				meta: { authRequired: true },
				component: () => import('../views/pages/content/user/customer')
		},
		{
				path: '/customer/action/add',
				name: 'Customer Form Add',
				meta: { authRequired: true },
				component: () => import('../views/pages/content/user/customer/form')
		},
		{
				path: '/customer/action/edit/:id',
				name: 'Customer Form Edit',
				meta: { authRequired: true },
				component: () => import('../views/pages/content/user/customer/form')
		},
		
		{
				path: '/admin',
				name: 'Admin',
				meta: { authRequired: true },
				component: () => import('../views/pages/content/user/admin')
		},
		{
				path: '/admin/action/add',
				name: 'Admin Form Add',
				meta: { authRequired: true },
				component: () => import('../views/pages/content/user/admin/form')
		},
		{
				path: '/admin/action/edit/:id',
				name: 'Admin Form Edit',
				meta: { authRequired: true },
				component: () => import('../views/pages/content/user/admin/form')
		},
		
		{
				path: '/property/offer',
				name: 'Offer Property',
				meta: { authRequired: true },
				component: () => import('../views/pages/content/offer_property')
		},
		{
				path: '/property/offer/detail/:id',
				name: 'Offer Property Form Add',
				meta: { authRequired: true },
				component: () => import('../views/pages/content/offer_property/form')
		},
]
